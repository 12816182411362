import { useNavigate } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { trackEvent } from "~/utils/analytics";

export function LuckyButton() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleLuckyClick = async () => {
        try {
            const response = await fetch('/api/lucky');
            const data = await response.json();

            trackEvent('lucky_button', {
                link: data.link,
            });

            if (data.link) {
                navigate(data.link);
            }
        } catch (error) {
            console.error('Failed to get lucky event:', error);
        }
    };

    return (
        <button
            onClick={handleLuckyClick}
            type="button"
            className="group relative inline-flex items-center gap-2 px-4 py-2 text-sm rounded-lg transition-all duration-300"
            title={t('lucky.tooltip')}
        >
            {/* Background layer */}
            <div className="absolute inset-0 bg-indigo-600 group-hover:bg-indigo-500 rounded-lg transition-colors" />
            
            {/* Animated border layer */}
            <div className="absolute inset-[-2px] rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 opacity-0 group-hover:opacity-100 blur-sm transition-all duration-300" />
            <div className="absolute inset-[-2px] rounded-lg animate-border bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 opacity-0 group-hover:opacity-100 transition-all duration-300" />

            {/* Content layer */}
            <div className="relative flex items-center gap-2 text-white z-10">
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    strokeWidth="1.5" 
                    stroke="currentColor" 
                    className="size-6 group-hover:animate-pulse"
                    aria-hidden="true"
                    role="img"
                >
                    <title>Magic</title>
                    <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" 
                    />
                </svg>
                <span>
                    {t('lucky.button')}
                </span>
            </div>
        </button>
    );
} 